import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
} from 'reactstrap';
import classnames from 'classnames';
import Loader from 'react-loader-spinner';

import { getBankAccounts, refBankOp, allRequests } from '../../helpers/api';
import Breadcrumbs from '../../components/Common/Breadcrumb';

class NewBankOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromAccount: null,
      accountNumber: null,
      bankName: null,
      requestField: null,
      amount: null,
      opDate: null,
      pending: false,
      error: null,
      banks: [],
      requests: [],
      request: null,
      activeTab: '1',
      processing: false,
      isReserve: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount = async () => {
    const banks = await getBankAccounts();
    this.setState({ banks });
    const res = await allRequests();
    this.setState({ requests: res.data.enrichedRequests });
  };

  updateFromAccount = (event) => {
    this.setState({
      fromAccount: event.target.value,
    });
  };

  updateReceiver = (event) => {
    this.setState({
      receiver: event.target.value,
    });
  };

  updateRequestField = (event) => {
    this.setState({ requestField: event.target.value });
  };

  updateRequest = (event) => {
    this.setState({ request: event.target.value });
  };

  updateAmount = (event) => {
    this.setState({ amount: event.target.value });
  };

  updateOpDate = (event) => {
    this.setState({ opDate: event.target.value });
  };

  referenceBankOp = () => {
    if (!this.state.fromAccount || this.state.fromAccount.length < 4)
      this.setState({ error: 'Invalid from account!', pending: false });
    else if (!this.state.opDate)
      this.setState({ error: 'Invalid operation date!', pending: false });
    else if (!this.state.receiver || this.state.receiver.length < 10)
      this.setState({ error: 'Invalid receiver account!', pending: false });
    else if (!this.state.amount || this.state.amount < 1)
      this.setState({ error: 'Invalid amount!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = refBankOp(
        this.state.fromAccount,
        new Date(this.state.opDate).toISOString(),
        this.state.requestField,
        this.state.amount,
        'EUR',
        this.state.receiver,
        this.state.activeTab === '1' ? 'C' : 'D',
        this.state.request,
        this.state.isReserve,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            window.location = '/bank';
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  updateCode = (event) => {
    this.setState({ code: event.target.value });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="bank"
              breadcrumbItem="Reference a new Bank operation"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-list-plus"></i> Reference a new Bank
                      operation
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '1',
                            })}
                            onClick={() => {
                              this.toggleTab('1');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Credit</p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '2',
                            })}
                            onClick={() => {
                              this.toggleTab('2');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Debit</p>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="credit">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>From account</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateFromAccount}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Operation field</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateRequestField}
                                    value={this.state.requestField}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Request reference <small>(if applicable)</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <select
                                    className="form-control"
                                    onChange={this.updateRequest}
                                  >
                                    <option value={null}>Select request</option>
                                    {this.state.requests.map(
                                      (req) =>
                                        req.request.status === 'PENDING' && (
                                          <option value={req.request.reference}>
                                            {req.request.reference} |{' '}
                                            {req.request.action} -{' '}
                                            {req.client.name}
                                          </option>
                                        ),
                                    )}
                                  </select>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Receiving account</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <select
                                    className="form-control"
                                    onChange={this.updateReceiver}
                                  >
                                    <option>Select bank account</option>
                                    {this.state.banks.map((bank) => (
                                      <option value={bank.accountNumber}>
                                        {bank.accountName} |{' '}
                                        {bank.accountNumber}
                                      </option>
                                    ))}
                                  </select>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Amount [EUR]</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    onChange={this.updateAmount}
                                    value={this.state.amount}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Operation date</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="datetime-local"
                                    className="form-control"
                                    onChange={this.updateOpDate}
                                    value={this.state.opDate}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Is collateral reserve ?
                              <br />
                              <small>Click to update reserve balance</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <input
                                    type="checkbox"
                                    onChange={(evt) => {
                                      this.setState({ isReserve: evt.target.checked })
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Label>
                            To validate this operation TrustBuilder
                            Authenticator is required
                          </Label>
                          {this.state.pending ? (
                            <div className="mt-2">
                              <Loader
                                type="Puff"
                                color="#50a5f1"
                                height={50}
                                width={50}
                              />
                              <p className="mt-2 text-info">
                                Notification sent onto your TrustBuilder
                                Authenticator application.{' '}
                                <strong>
                                  Set your pin code to save bank account...
                                </strong>
                              </p>
                            </div>
                          ) : (
                            <div className="mt-2">
                              <Button
                                type="button"
                                color="success"
                                onClick={this.referenceBankOp}
                              >
                                <strong className="text-dark ">
                                  Reference Credit operation
                                </strong>
                              </Button>
                            </div>
                          )}
                        </TabPane>
                        <TabPane tabId="2" id="debit">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>From account</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <select
                                    className="form-control"
                                    onChange={this.updateFromAccount}
                                  >
                                    <option>Select bank account</option>
                                    {this.state.banks.map((bank) => (
                                      <option value={bank.accountNumber}>
                                        {bank.accountName} |{' '}
                                        {bank.accountNumber}
                                      </option>
                                    ))}
                                  </select>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Operation field</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateRequestField}
                                    value={this.state.requestField}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Request reference <small>(if applicable)</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <select
                                    className="form-control"
                                    onChange={this.updateRequest}
                                  >
                                    <option value={null}>Select request</option>
                                    {this.state.requests.map(
                                      (req) =>
                                        req.request.status === 'PENDING' && (
                                          <option value={req.reference}>
                                            {req.request.reference} |{' '}
                                            {req.request.action} -{' '}
                                            {req.client.name}
                                          </option>
                                        ),
                                    )}
                                  </select>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Receiving account</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateReceiver}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Amount [EUR]</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    onChange={this.updateAmount}
                                    value={this.state.amount}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Operation date</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="datetime-local"
                                    className="form-control"
                                    onChange={this.updateOpDate}
                                    value={this.state.opDate}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Label>
                            To validate this operation TrustBuilder
                            Authenticator is required
                          </Label>
                          {this.state.pending ? (
                            <div className="mt-2">
                              <Loader
                                type="Puff"
                                color="#50a5f1"
                                height={50}
                                width={50}
                              />
                              <p className="mt-2 text-info">
                                Notification sent onto your TrustBuilder
                                Authenticator application.{' '}
                                <strong>
                                  Set your pin code to save bank account...
                                </strong>
                              </p>
                            </div>
                          ) : (
                            <div className="mt-2">
                              <Button
                                type="button"
                                color="success"
                                onClick={this.referenceBankOp}
                              >
                                <strong className="text-dark ">
                                  Reference Debit operation
                                </strong>
                              </Button>
                            </div>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default NewBankOperation;
